<template lang="pug">
b-table(:data='invoices' :loading='isLoading' narrowed hoverable mobile-cards striped searchable)
  b-table-column(field='id' label='Id' sortable searchable v-slot='props' )
    router-link(:to='{ name: "invoice", params: { id: props.row.id } }') {{ props.row.id }}
    invoice-file-link.ml-3(:invoice='props.row' only-icons)
  b-table-column(field='contractCode' label='Contrato' sortable searchable v-slot='props' v-if='showContractInfo')
    router-link(:to='{ name: "contract", params: { id: props.row.contractId } }' v-if='props.row.contractId') {{ props.row.contractCode }}
  b-table-column(field='assignedName' label='Cliente' sortable searchable v-slot='props' v-if='showContractInfo') {{ props.row.assignedName }}
  b-table-column(field='date' label='Fecha de la factura' sortable searchable v-slot='props')
    | {{ props.row.date | date }}
  b-table-column(field='startDate' label='Inicio ciclo' sortable searchable v-slot='props') {{ props.row.startDate | date }}
  b-table-column(field='endDate' label='Fin ciclo' sortable searchable v-slot='props') {{ props.row.endDate | date }}
  b-table-column(field='expireDate' label='Fecha límite pago' sortable searchable v-slot='props') {{ props.row.expireDate | date }}
  b-table-column(field='statusDescription' label='Estado' sortable searchable v-slot='props')
    .no-wrap
      invoice-status-indicator.mr-1(:invoice='props.row' only-icon)
      a(@click='editInvoice(props.row)') {{ props.row.statusDescription }}
  b-table-column(field='devicesCount' label='Equipos' sortable searchable numeric v-slot='props') {{ props.row.devicesCount }}
  b-table-column(field='total' label='Valor' sortable searchable numeric v-slot='props') {{ props.row.total | currency }}
  template(slot='empty')
    .no-data-found
  b-modal(v-model='invoiceModalActive' has-modal-card trap-focus aria-role='dialog' aria-modal)
    modal-card(v-if='invoiceSelected' title='Editar factura')
      app-form(@submit.prevent='submit' :is-saving='isSaving')
        field(label='Estado' field='status')
          app-select(id='status' :enum='invoiceStatusEnum' :enum-names='invoiceStatusDescriptions' v-model.number='invoiceSelected.status' required)
</template>

<script>
import InvoiceStatusIndicator from './InvoiceStatusIndicator'
import InvoiceFileLink from './InvoiceFileLink'
import { invoiceStatusEnum, invoiceStatusDescriptions } from '@/constants/enums'
import invoicesService from '@/services/invoices.service'
import toastService from '@/services/toast.service'

export default {
  components: { InvoiceFileLink, InvoiceStatusIndicator },
  props: {
    invoices: { type: Array, required: true },
    showContractInfo: { type: Boolean },
    isLoading: { type: Boolean }
  },
  data () {
    return {
      invoiceModalActive: false,
      invoiceSelected: null,
      isSaving: false,
      invoiceStatusEnum,
      invoiceStatusDescriptions
    }
  },
  watch: {
    invoices: {
      inmediate: true,
      handler: function () {
        this.invoices.forEach(invoice => {
          this.formatInvoice(invoice)
        })
      }
    }
  },
  methods: {
    formatInvoice (invoice) {
      invoice.statusDescription = invoiceStatusDescriptions[invoice.status]
    },
    editInvoice (invoice) {
      this.invoiceSelected = { ...invoice }
      this.invoiceModalActive = true
    },
    async submit () {
      this.isSaving = true
      const result = await invoicesService.save(this.invoiceSelected)
      if (result) {
        const invoice = this.invoices.find(i => i.id === this.invoiceSelected.id)
        toastService.show('Factura actualizada')
        if (invoice) {
          invoice.status = this.invoiceSelected.status
          this.formatInvoice(invoice)
        }
        this.invoiceModalActive = false
      }
      this.isSaving = false
    }
  }
}
</script>
