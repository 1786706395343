<template lang="pug">
.container
  page-title(title='Facturas')
    router-link.button.is-primary(:to='createInvoice') Nueva factura
  .card
    .card-content
      invoices-table(:invoices='invoices' :is-loading='isLoading' show-contract-info)
</template>

<script>
import invoicesService from '@/services/invoices.service'
import InvoicesTable from './blocks/InvoicesTable'
import { createInvoice } from '@/router/routes'

export default {
  components: { InvoicesTable },
  data () {
    return {
      invoices: [],
      isLoading: false,
      createInvoice
    }
  },
  created () {
    this.getInvoices()
  },
  methods: {
    async getInvoices () {
      this.isLoading = true
      const result = await invoicesService.getAll()
      if (result) {
        this.invoices = result
      }
      this.isLoading = false
    }
  }
}
</script>
